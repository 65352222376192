/**
 * Created by slava on 9/8/17
 */

var FieldBoosterView = cc.Node.extend({
    ctor: function (booster) {
        this._super();

        this.booster = booster;

        this.setAnchorPoint(0.5, 0.5);

        this.createIcon();

        booster.onStopListener = this.createListener(this.stop.bind(this));
        booster.onActivateListener = this.createListener(this.showActivated.bind(this));
        booster.onDeactivateListener = this.createListener(this.showDeactivated.bind(this));
        booster.onHurtCellsListener = this.createListener(this.onHurtCellsAnimation.bind(this));
        booster.onGetView = this.createListener(function () {
            return this;
        }.bind(this));

        var styles = cleverapps.styles.FieldBoosterView;

        if (styles.background) {
            this.bg = this.createBackground(bundles.game.frames.booster_icon_bg_png);

            this.bgOn = this.createBackground(bundles.game.frames.booster_icon_bg_on_png);
            this.bgOn.setVisible(false);
        }

        if (this.booster.isDisabled() && styles.locked) {
            this.createLockIcon();
        }

        if (booster.isDisabled() && styles.hideWhenDisabled) {
            this.setVisible(false);
        }

        this.amount = new BoosterAmountView(booster);
        this.amount.setPositionRound(this.width + styles.amount.x, styles.amount.y);
        this.addChild(this.amount);

        this.updateSize();

        this.createPrice();

        this.onChangeAmount();

        if (booster.isDisabled()) {
            this.disable();
        } else {
            this.enable();
        }
    },

    createPrice: function () {
        var styles = cleverapps.styles.FieldBoosterView.price;

        var content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.content.width, styles.content.height);

        var price = this.price = new TextWithIcon("$$" + this.booster.getPrice().amount, {
            font: cleverapps.styles.FONTS.FIELD_BOOSTER
        });

        var ads = this.ads = new TextWithIcon("## x1", {
            font: cleverapps.styles.FONTS.FIELD_BOOSTER
        });

        content.addChild(price);
        price.setPosition(content.width / 2, content.height / 2);
        content.addChild(ads);
        ads.setPosition(content.width / 2, content.height / 2);

        var priceButton = this.priceButton = new cleverapps.UI.Button({
            content: content,
            width: styles.width,
            height: styles.height,
            type: styles.type,
            keepRadius: true,
            onClicked: function () {
                Game.currentGame.boosters.onTouchBegan(this.booster);
            }.bind(this)
        });

        this.addChild(priceButton);
        priceButton.setPositionRound(styles);
    },

    updateSize: function () {
        var styles = cleverapps.styles.FieldBoosterView;

        if (styles.scales) {
            this.setScale(styles.scales[cleverapps.resolution.mode]);
            this.baseScale = styles.scales[cleverapps.resolution.mode];
        }
    },

    stop: function () {
        if (this.touchListener) {
            this.touchListener.remove();
            this.touchListener = undefined;
        }
    },

    enable: function () {
        cleverapps.UI.applyHover(this);

        this.touchListener = cleverapps.UI.onDrag(this, {
            instantDrag: true,
            onOuterTouch: this.onOuterTouch.bind(this),
            onDragStart: this.onTouchBegan.bind(this),
            followPointer: this.onTouchMoved.bind(this),
            onDragEnd: this.onTouchEnded.bind(this)
        });

        this.booster.on("changeAmount", this.createListener(this.onChangeAmount.bind(this)), this);
        cleverapps.adsLimits.on("update", this.onChangeAmount.bind(this), this);
        if (cleverapps.config.soft) {
            cleverapps.user.on("changeSoft", this.onChangeAmount.bind(this), this);
        } else {
            cleverapps.user.on("changeHard", this.onChangeAmount.bind(this), this);
        }
    },

    disable: function () {
        this.amount.setVisible(false);
        this.priceButton.setVisible(false);
        this.icon.setVisible(false);
    },

    onChangeAmount: function () {
        var boosterState = this.booster.getState();

        if (boosterState === BaseBooster.AMOUNT_STATE) {
            this.amount.setAmount(this.booster.getAmount());
            this.priceButton.setVisible(false);
        } else {
            this.amount.setVisible(false);
            this.priceButton.setVisible(true);
            this.price.setVisible(boosterState === BaseBooster.PRICE_STATE);
            this.ads.setVisible(boosterState === BaseBooster.ADS_STATE);
        }
    },

    createLockIcon: function () {
        var styles = cleverapps.styles.FieldBoosterView;

        var lockIcon = new cc.Sprite(bundles.game.frames.locked_png);
        this.bg.addChild(lockIcon);

        lockIcon.setPositionRound(styles.locked.x, styles.locked.y);
    },

    createBackground: function (image) {
        var styles = cleverapps.styles.FieldBoosterView;

        var bg;
        var bgStyles = styles.background[cleverapps.resolution.mode];
        if (bgStyles && bgStyles.scale9) {
            bg = cleverapps.UI.createScale9Sprite(image, bgStyles.scale9);
        } else {
            bg = new cc.Sprite(bundles.game.frames.booster_icon_bg_png);
        }
        bg.setLocalZOrder(-1);
        bg.setPositionRound(this.width / 2 + styles.bgOffset.x, this.height / 2 + styles.bgOffset.y);

        bg.updateSize = function () {
            var bgStyles = styles.background[cleverapps.resolution.mode];
            if (bgStyles && bgStyles.scale9) {
                bg.setContentSize(bgStyles.width, bgStyles.height);
            }
        };

        bg.updateSize();

        this.addChild(bg);

        return bg;
    },

    createIcon: function () {
        this.icon = new cc.Sprite(this.booster.icon);
        this.setContentSize2(this.icon.width, this.icon.height);
        this.icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.icon);
    },

    showActivated: function () {
        this.icon.setSpriteFrame(this.booster.icon_active);
        this.setBackground(true);
    },

    showDeactivated: function () {
        this.icon.setSpriteFrame(this.booster.icon);
        this.setBackground(false);
    },

    onTouchBegan: function () {
        return Game.currentGame.boosters.onTouchBegan(this.booster);
    },

    onOuterTouch: function (touch) {
        var targetCell = Game.currentGame.field.onGetView().getTouchedCell(touch);

        this.runAction(new cc.CallFunc(function () {
            Game.currentGame.boosters.onTouchOut(this.booster, targetCell);
        }.bind(this)));
    },

    onTouchMoved: function (touch) {
        Game.currentGame.boosters.onTouchMoved(touch);
    },

    onTouchEnded: function (touch) {
        if (this.containsTouch(touch)) {
            return;
        }

        var targetCell = Game.currentGame.field.onGetView().getTouchedCell(touch);
        Game.currentGame.boosters.onTouchEnded(targetCell);
    },

    containsTouch: function (touch) {
        var point = this.convertTouchToNodeSpace(touch);
        return cc.rectContainsPoint(cc.rect(0, 0, this.width, this.height), point);
    },

    onHurtCellsAnimation: function () {

    },

    setBackground: function (isOn) {
        if (cleverapps.styles.FieldBoosterView.background) {
            this.bg.visible = !isOn;
            this.bgOn.visible = isOn;
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FIELD_BOOSTER: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.FieldBoosterView = {
    zoomScale: 1.05,

    amount: {
        x: -16,
        y: 10
    },

    price: {
        width: 130,
        height: 55,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -25 },
        content: {
            width: 110,
            height: 35
        },
        type: cleverapps.styles.UI.Button.Images.small_button_green
    },

    bgOffset: {
        x: 0,
        y: 0
    },

    locked: {
        x: { align: "center" },
        y: { align: "center" }
    }
};
