/**
 * Created by mac on 2019-10-14
 */

var QuestItemView = cc.Node.extend({ 
    ctor: function (item, minified) {
        this._super();

        var state = "default";
        if (minified) {
            state = "minified";
        } else if (item.status >= item.amount) {
            state = "completed";
        }

        var styles = cleverapps.styles.QuestItemView;
        if (minified) {
            styles = cleverapps.overrideStyles(styles, cleverapps.styles.QuestItemView.minified, true);
        } else if (item.status >= item.amount) {
            styles = cleverapps.overrideStyles(styles, cleverapps.styles.QuestItemView.completed, true);
        }

        this.setContentSize(styles);
        this.setAnchorPoint(0.5, 0.5);

        var itemIcon = new cc.Sprite(bundles.farm_items.frames["item" + cleverapps.meta.getSelectedLocation().config.questItems.indexOf(item.name)]);
        itemIcon.setPositionRound(styles.itemIcon);
        this.addChild(itemIcon);

        if (state !== "minified") {
            var itemBg = this.bg = cleverapps.UI.createScale9Sprite(styles.bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            itemBg.setContentSize2(styles);
            itemBg.setPosition(this.width / 2, this.height / 2);
            this.addChild(itemBg, -1);
        }

        if (state === "default") {
            var icon = new cc.Sprite(bundles.farm_windows.frames.itemIcon);
            icon.setPositionRound(styles.icon);
            this.addChild(icon);
        }

        if (state === "completed") {
            var checkMark = new cc.Sprite(styles.mark.image);
            checkMark.setPositionRound(styles.mark);
            checkMark.setScale(styles.mark.scale);
            this.addChild(checkMark);
        }

        if (state === "default") {
            var amount = this.amount = cleverapps.UI.generateTTFText(item.status + "/" + item.amount, styles.amount.font);
            amount.setPositionRound(styles.amount);
            this.addChild(amount);
        }
    }
});

cleverapps.styles.FONTS.ITEM_AMOUNT_TEXT = {
    size: 40,
    color: cleverapps.styles.COLORS.WHITE,
    shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
};

cleverapps.styles.QuestItemView = {
    bg: bundles.farm_windows.frames.itemBg,
    width: 205,
    height: 285,

    icon: {
        x: { align: "right", dx: -32 },
        y: { align: "top", dy: -35 }
    },

    itemIcon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    amount: {
        font: cleverapps.styles.FONTS.ITEM_AMOUNT_TEXT,
        x: { align: "center" },
        y: { align: "bottom", dy: 25 }
    },

    mark: {
        image: bundles.farm_windows.frames.checkMark,
        x: { align: "center" },
        y: { align: "bottom", dy: 25 },
        scale: 1.5
    },

    completed: {
        bg: bundles.farm_windows.frames.itemCompletedBg
    },

    minified: {
        bg: bundles.farm_windows.frames.itemMiniBg,
        width: 175,
        height: 190
    }
};