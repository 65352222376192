/**
 * Created by mac on 8/03/17.
 */

var FloorView = cc.Node.extend({
    ctor: function (field) {
        this._super();

        this.setCascadeOpacityEnabled(true);

        this.onTouchBeganListener = function () {};
        this.onTouchMovedListener = function () {};
        this.onTouchEndedListener = function () {};

        field.iterateFloor(function (row, col) {
            this.addBackground(col, row);
        }.bind(this));

        field.iterateTiles(function (tile) {
            this.addTile(tile);

            if (tile.constructor === TransporterTile) {
                tile.onSetImage();
                tile.onAnimate();
            }
        }.bind(this));

        field.onGetFloorBackgroundView = this.createListener(function (x, y) {
            return this.getChildByTag(x * Field.SIZE + y);
        }.bind(this));

        field.on("addTile", this.addTile.bind(this), this);

        this.createBorders(field);
    },

    addBackground: function (x, y) {
        var image = [bundles.game.frames.tile_background_1, bundles.game.frames.tile_background_2][(x + y) % 2];
        var background = cleverapps.UI.createScale9Sprite(image, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize(cleverapps.styles.BaseCellView.CELL_SIZE_PX, cleverapps.styles.BaseCellView.CELL_SIZE_PX);
        background.setPosition(BaseCellView.alignInTheGrid(x, y));
        this.addChild(background, -1, x * Field.SIZE + y);
    },

    addTile: function (tile) {
        var ViewClass = tile.getViewClass();
        var view = cc.pool.hasObject(ViewClass) ? cc.pool.getFromPool(ViewClass, tile) : new ViewClass(tile);
        view.beforeShowUpAnimation();
        this.addChild(view);
    },

    createBorders: function (filed) {
        var area = [];

        filed.iterateFloor(function (row, col) {
            area.push({ x: col, y: row });
        });

        cleverapps.BorderRenderer.place(
            area,
            function (imageNames, cell) {
                imageNames.forEach(function (imageName) {
                    var resource = bundles.game.frames["border_" + imageName];
                    if (!resource) {
                        return;
                    }

                    var image = new cc.Sprite(resource);
                    image.setLocalZOrder(1);

                    var cellSize = cleverapps.styles.BaseCellView.CELL_SIZE_PX;
                    var horDir = imageName.includes("right") ? 1 : imageName.includes("left") ? -1 : 0;
                    var vertDir = imageName.includes("up") ? 1 : imageName.includes("down") ? -1 : 0;

                    var dx = horDir * (cellSize / 2 + image.width / 2);
                    var dy = vertDir * (cellSize / 2 + image.height / 2);

                    if (imageName.includes("inner")) {
                        dx = horDir * (cellSize / 2 - image.width / 2);
                        image.setLocalZOrder(2);
                    }

                    image.setAnchorPoint(0.5, 0.5);
                    var pos = BaseCellView.alignInTheGrid(cell.x, cell.y);
                    image.setPosition(pos.x + dx, pos.y + dy);

                    this.addChild(image);
                }.bind(this));
            }.bind(this)
        );
    }
});
