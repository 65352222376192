/**
 * Created by mac on 2019-10-12
 */

var BuildingStartWindow = LevelStartWindow.extend({
    onWindowLoaded: function (options) {
        this.building = options.building;

        this._super(options.level.getStartWindowOptions());

        this.addSubTitle();

        if (this.boostersBefore) {
            var providedItem = this.createProvidedItem();
            this.window.addChild(providedItem);
            providedItem.setPositionRound(cleverapps.styles.BuildingStartWindow.providedItem);
            providedItem.setLocalZOrder(10);

            var scene = cleverapps.scenes.getRunningScene();
            var scenePos = scene.convertToNodeSpace(providedItem.getParent().convertToWorldSpace(providedItem.getPosition()));
            var leftBorder = scenePos.x - providedItem.width / 2;

            if (leftBorder < 0) {
                providedItem.setScale(0.7);
            }
        }
    },

    getTitleText: function () {
        return Messages.get("building." + this.building.name);
    },

    createSubTitle: function () {
        var styles = cleverapps.styles.BuildingStartWindow.subTitle;
        var bg = cleverapps.UI.createScale9Sprite(bundles.farm_windows.frames.sub_title_bg_png);
        var text = cleverapps.UI.generateOnlyText("BuildingStartWindow.subTitle", cleverapps.styles.FONTS.BUILDING_START_WINDOW_SUBTITLE, { level: this.building.level + 1 });
        text.setPosition(bg.width / 2, bg.height / 2 + styles.text.offsetY);
        text.fitTo(styles.text.width);
        bg.addChild(text);
        return bg;
    },

    addSubTitle: function () {
        var subTitle = this.createSubTitle();
        this.window.addChild(subTitle, 2);
        subTitle.setPositionRound(cleverapps.styles.BuildingStartWindow.subTitle);
    },

    listContent: function () {
        var items = this._super();

        if (!this.boostersBefore) {
            var padding = cleverapps.styles.BuildingStartWindow.providedItem.noBoosterPadding;
            items.push(cleverapps.UI.wrapWithPadding(this.createProvidedItem(), padding));
        }

        return items;
    },

    createProvidedItem: function () {
        var styles = cleverapps.styles.BuildingStartWindow.providedItem;

        var itemBg = new cleverapps.Spine(bundles.farm_windows.jsons.items_bg_json);
        itemBg.setAnimation(0, "open", false);
        itemBg.addAnimation(0, "idle", true);

        var runAction = function (item, delay) {
            var baseScale = item.scale;
            item.setRotation(styles.rotation);
            item.setScale(0);
            item.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.5, baseScale).easing(cc.easeBackOut())
            ));
        };

        var itemIcon = new cc.Sprite(bundles.farm_items.frames["item" + cleverapps.meta.getSelectedLocation().config.questItems.indexOf(this.building.provides)]);
        itemBg.addChild(itemIcon);
        itemIcon.setPositionRound(styles.icon);
        var iconScale = Math.min(1, styles.icon.maxWidth / itemIcon.width, styles.icon.maxHeight / itemIcon.height);
        itemIcon.setScale(iconScale);
        runAction(itemIcon, 0.6);

        var text = cleverapps.UI.generateOnlyText("BuildingStartWindow.itemTitle", cleverapps.styles.FONTS.SMALL_WHITE_TEXT);
        text.fitTo(styles.text.maxWidth);
        itemBg.addChild(text);
        text.setPositionRound(styles.text);
        runAction(text, 0.75);

        cleverapps.tooltipManager.create(itemBg, {
            text: Messages.get("ProvidedItemTooltip"),
            location: cleverapps.UI.Tooltip.LOCATION_BELOW
        });

        return itemBg;
    },

    listBundles: function (options) {
        return this._super(options).concat(["farm_windows"]);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BUILDING_START_WINDOW_SUBTITLE: {
        size: 43,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.BuildingStartWindow = cleverapps.overrideStyles(cleverapps.styles.LevelStartWindow, {
    providedItem: {
        rotation: -6,
        x: { align: "left", dx: -50 },
        y: { align: "top", dy: 55 },

        noBoosterPadding: {
            x: 140,
            y: 30
        },

        text: {
            maxWidth: 125,
            x: { align: "center", dx: -13 },
            y: { align: "top", dy: -18 }
        },

        icon: {
            maxWidth: 115,
            maxHeight: 115,
            x: { align: "center" },
            y: { align: "center" }
        }
    },

    subTitle: {
        x: { align: "center" },
        y: { align: "top", dy: -83 },
        text: {
            width: 130,
            offsetY: 2
        }
    }
});