/**
 * Created by olga on 28.01.2025
 */

Match3TutorialHelper = {};

Match3TutorialHelper.createMoveStep = function (data) {
    var force = {
        text: data.textBlock.text || "",
        finger: {
            path: data.control
        }
    };

    var step = {
        name: "move",
        onStart: function () {
            var highlights = this.highlights = Match3TutorialHelper.getHighlights(data);

            var game = Game.currentGame;
            
            this.cells = data.control.map(function (control) {
                return game.field.getCell(control[0], control[1]);
            });

            this.force = cleverapps.forces.create(this.cells[0].onGetView(), force, {
                highlights: highlights,
                importantNodes: highlights
            });

            Game.currentGame.hideAvailableMove();

            cleverapps.focusManager.showControlsWhileFocused(["field", "panel_controls", "panel_info"]);
            cleverapps.focusManager.setEventNodes([Game.currentGame.field.onGetView()]);
        },

        onExecution: function () {
            if (cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(step);
};

Match3TutorialHelper.createBoosterActivationStep = function (data) {
    var force = {
        text: data.textBlock.text || ""
    };

    var step = {
        name: "boosterActivation",
        onStart: function () {
            var boosterView = Game.currentGame.boosters.onGetBoosterView(data.booster);
            this.force = cleverapps.forces.create(boosterView, force);
           
            var amount = cleverapps.boosters.getAmount(data.booster) + 1;
            cleverapps.boosters.setAmount(data.booster, amount);
        },

        onExecution: function () {
            Game.currentGame.counter.trigger();
        },

        onFinish: function () {
            if (cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(step);
};

Match3TutorialHelper.createBoosterCellStep = function (data) {
    var force = {
        text: data.textBlock.text || "",
        finger: false,
        closeByTouchInShadow: true
    };

    var step = {
        name: "boosterCell",
        onStart: function () {
            Game.currentGame.shownBoosterCellTutorial = true;

            var boosterCell;

            Game.currentGame.field.iterateCells(function (cell) {
                if (cell instanceof BoosterCell) {
                    boosterCell = cell;
                }
            });

            if (boosterCell) {
                var highlights = Match3TutorialHelper.getHighlights({
                    light: [[boosterCell.x, boosterCell.y]]
                });

                this.force = cleverapps.forces.create(boosterCell.onGetView(), force, {
                    highlights: highlights
                });

                cleverapps.forces.onceForceClosed = function () {
                    this.execute();
                    Game.currentGame.counter.trigger();
                }.bind(this);
            } else {
                this.execute();
            }
        }
    };

    return new TutorialStep(step);
};

Match3TutorialHelper.getHighlights = function (data) {
    var game = Game.currentGame;
    var highlights = [cleverapps.scenes.getMovingNode()];

    if (data.light) {
        for (var i = 0; i < data.light.length; i++) {
            var x = data.light[i][0];
            var y = data.light[i][1];

            var cell = game.field.getCell(x, y);
            while (cell) {
                highlights.push(cell.onGetView());
                cell = cell.innerCell;
            }

            var tile = game.field.getFloorTile(x, y);
            if (tile) {
                var hideTile = tile instanceof RabbitTile && tile.size === 2 && tile.isRoot && data && data.cellBooster;
                if (!hideTile) {
                    highlights.push(tile.onGetView());
                }
            }

            highlights.push(game.field.onGetFloorBackgroundView(x, y));
            highlights = highlights.concat(game.field.onGetBarriersViews(x, y));
        }
    }

    if (data.moves) {
        highlights.push(game.onGetMovesView());
    }

    if (data.booster !== undefined) {
        highlights.push(game.boosters.onGetBoosterView(data.booster));
    }

    if (data.goals || data.goalsForce) {
        highlights.push(game.goals.onGetView());
    }

    return highlights.filter(Boolean);
};

Match3TutorialHelper.checkTutorialCell = function (cell, shouldHighlightCells) {
    var activeScenario = cleverapps.tutorial.getActive();
    var tutorialCells = activeScenario && activeScenario.getCurrentStep().cells;

    if (shouldHighlightCells) {
        var currentHighlights = activeScenario && activeScenario.getCurrentStep().highlights || [];
        var newHighlights = [];

        currentHighlights.forEach(function (highlight) {
            var existsInCells = tutorialCells && tutorialCells.some(function (tutorialCell) {
                return highlight.cell && highlight.cell.x === tutorialCell.x && highlight.cell.y === tutorialCell.y;
            });

            if (!existsInCells && highlight.cell) {
                newHighlights.push(highlight.cell);
            }
        });

        if (tutorialCells) {
            tutorialCells = tutorialCells.concat(newHighlights);
        } else if (newHighlights.length > 0) {
            tutorialCells = newHighlights;
        }
    }

    if (!tutorialCells) {
        return true;
    }

    return tutorialCells.some(function (tutorialCell) {
        return cell.x === tutorialCell.x && cell.y === tutorialCell.y;
    });
};