/**
 * Created by mac on 7/24/17.
 */

var FieldView = cc.Node.extend({
    avoidNode: "FieldView",

    ctor: function (field) {
        this._super();

        this.field = field;
        field.onGetView = function () {
            return this;
        }.bind(this);

        this.setLocalZOrder(5);
        this.setAnchorPoint(0.5, 0.5);

        this.debugId = "fieldView";

        var floorView = this.floorView = new FloorView(field);
        floorView.setAnchorPoint(0.5, 0.5);
        this.addChild(floorView);

        var barriersView = this.barriersView = new BarriersView(field, BaseCellView.alignInTheGrid);
        barriersView.setAnchorPoint(0.5, 0.5);
        this.addChild(barriersView);

        this.innerContainer = new cc.Node();
        this.innerContainer.setAnchorPoint(0.5, 0.5);
        this.addChild(this.innerContainer);

        this.selection = new cc.Sprite(bundles.game.frames.selected_cell);
        this.hideSelection();
        this.addChild(this.selection, 1);

        var availableView = new AvailableMoveView(Game.currentGame.availableMove);
        this.addChild(availableView);

        cleverapps.UI.onDrag(this, {
            instantDrag: true,
            onDragStart: this.onTouchBegan.bind(this),
            followPointer: this.onTouchMoved.bind(this),
            onDragEnd: this.onTouchEnded.bind(this)
        }, this);

        this.startLocation = undefined;

        cleverapps.aims.registerTarget("field", this, {
            noTargetDelta: true,
            flyingUnderShadow: true,
            flyingAnimation: Reward.NO_ANIMATION
        });

        if (!cleverapps.environment.isSceneWithPreview()) {
            field.startSmile();
        }

        field.on("showUpFloor", this.onShowUpFloor.bind(this), this);
        field.on("shakeField", this.shakeField.bind(this), this);
        field.on("bombByLeftMovesCreate", this.bombByLeftMovesCreate.bind(this), this);
        field.on("addCell", this.addCell.bind(this), this);
        field.on("addScore", this.addScore.bind(this), this);
        field.on("addClover", this.addClover.bind(this), this);
        field.on("changeSelected", this.changeSelected.bind(this), this);
        field.on("moveAnimation", this.moveAnimation.bind(this), this);
        field.on("bombByLeftMoves", this.onBombByLeftMovesAnimation.bind(this), this);
        field.on("debugHighlightCells", this.debugHighlightCells.bind(this), this);
        field.on("hide", this.hide.bind(this), this);
        field.on("showLantern", this.showLantern.bind(this), this);

        field.iterateCells(function (cell) {
            this.addCell(cell);

            if (cell.constructor === GumDecoratorCell) {
                cell.onSetBorders();
            }
        }.bind(this));
    },

    addCell: function (cell) {
        if (cell.viewExists) {
            return;
        }
        cell.viewExists = true;

        var ViewClass = cell.getViewClass();
        var view = cc.pool.hasObject(ViewClass) ? cc.pool.getFromPool(ViewClass, cell) : new ViewClass(cell);
        view.beforeShowUpAnimation();
        this.innerContainer.addChild(view);
    },

    adjustSize: function () {
        this.setContentSize(Field.SIZE_X * cleverapps.styles.BaseCellView.CELL_SIZE_PX, Field.SIZE_Y * cleverapps.styles.BaseCellView.CELL_SIZE_PX);

        this.floorView.setContentSize(this.getContentSize());
        this.barriersView.setContentSize(this.getContentSize());

        this.floorView.setPositionRound(this.width / 2, this.height / 2);
        this.barriersView.setPositionRound(this.width / 2, this.height / 2);

        this.innerContainer.setContentSize(this.getContentSize());
        this.innerContainer.setPositionRound(this.width / 2, this.height / 2);
    },

    onTouchBegan: function (touch) {
        var location = this.convertTouchToNodeSpace(touch);
        return this.onTouchBeganListener(location, this.getCellPosition(location));
    },

    getCellPosition: function (location) {
        return {
            row: Field.SIZE_Y - 1 - Math.floor(location.y / cleverapps.styles.BaseCellView.CELL_SIZE_PX),
            col: Math.floor(location.x / cleverapps.styles.BaseCellView.CELL_SIZE_PX)
        };
    },

    onTouchMoved: function (touch) {
        var location = this.convertTouchToNodeSpace(touch);
        this.onTouchMovedListener(location, this.getCellPosition(location));
    },

    onTouchEnded: function (touch) {
        var location = this.convertTouchToNodeSpace(touch);
        this.onTouchEndedListener(location, this.getCellPosition(location));
    },

    showSelection: function (row, col) {
        this.selection.setPosition(BaseCellView.alignInTheGrid(col, row));
        this.selection.setVisible(true);
    },

    hideSelection: function () {
        this.selection.setVisible(false);
    },

    moveAnimation: function (start, finish, validMove) {
        var moveAnimation = validMove ? cleverapps.styles.FieldView.animateValidMove : cleverapps.styles.FieldView.animateWrongMove;
        if (moveAnimation) {
            var animation = BaseAnimation.factory(moveAnimation);
            animation.setLocalZOrder(100);
            animation.runAnimate("animation");
            animation.setPositionRound(BaseCellView.alignInTheGrid((start.x + finish.x) / 2, (start.y + finish.y) / 2));
            animation.setRotation(start.y !== finish.y ? 90 : 0);
            this.addChild(animation);
        }
    },

    onBombByLeftMovesAnimation: function () {},

    completeAnimationOnResize: function () {
        this.stopAllActions();
    },

    onShowUpFloor: function (callback, silent) {
        if (silent) {
            this.setVisible(true);
            callback();
            return;
        }

        var scale = this.getScale();
        this.setScale(0.5);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(cleverapps.styles.FieldView.slideAnimation.field.delay),
            new cc.PlaySound(bundles.game.urls.game_start_effect),
            new cc.Show(),
            new cc.ScaleTo(0.3, scale).easing(cc.easeBackOut())
        ).setFinalize(function () {
            this.setVisible(true);
            this.adjustSize();
            callback();
        }.bind(this)));
    },

    hide: function (f) {
        var scale = this.getScale();

        this.field.hideCells(function () {
            this.runAction(
                new cc.ScaleTo(0.3, scale * 0.5).easing(cc.easeBackIn()).setFinalize(function () {
                    this.setVisible(false);
                    f();
                }.bind(this))
            );
        }.bind(this));
    },

    debugHighlightCells: function (cells) {
        if (this.highlightedCells) {
            this.highlightedCells.forEach(function (cell) {
                cell.removeFromParent();
            });
        }

        this.highlightedCells = [];

        var colors = {};

        Object.keys(Game.currentGame.goals.elements).forEach(function (goalId, index) {
            colors[goalId] = [
                new cc.Color(255, 0, 0, 255),
                new cc.Color(0, 255, 0, 255),
                new cc.Color(0, 0, 255, 255)
            ][index];
        });

        cells.forEach(function (cell) {
            var padding = 3;
            var width = cleverapps.styles.BaseCellView.CELL_SIZE_PX;
            var height = cleverapps.styles.BaseCellView.CELL_SIZE_PX;

            var highlight = new cc.DrawNode();
            highlight.setAnchorPoint(0.5, 0.5);
            highlight.setContentSize2(width, height);
            highlight.setPosition(BaseCellView.alignInTheGrid(cell.x, cell.y));
            this.addChild(highlight);

            highlight.drawRect(
                cc.p(padding, padding),
                cc.p(width - padding, height - padding),
                null,
                4,
                colors[cell.goalId]
            );

            highlight.runAction(new cc.Sequence(
                new cc.DelayTime(0.8),
                new cc.ScaleTo(0.2, 0),
                new cc.RemoveSelf()
            ));

            this.highlightedCells.push(highlight);
        }, this);
    },

    shakeField: function (params) {
        params = params || {};
        params.strength = cleverapps.styles.FieldView.shake;
        var target = this.innerContainer;
        params.base = {
            x: target.getParent().width / 2,
            y: target.getParent().height / 2,
            r: 0
        };
        var shakeAction = AnimationsLibrary.shake(target, params);
        target.stopAllActions();
        target.runAction(shakeAction);
    },

    getTouchedCell: function (touch) {
        var location = this.convertTouchToNodeSpace(touch);
        var cellPosition = this.getCellPosition(location);

        return this.field.cells[cellPosition.row] && this.field.cells[cellPosition.row][cellPosition.col];
    },

    bombByLeftMovesCreate: function () {},

    addScore: function (score, color, x, y) {
        var view = FieldScoreView.factory(score, color, BaseCellView.alignInTheGrid(x, y));
        if (view) {
            this.addChild(view);
        }
    },

    addClover: function (score, x, y) {
        if (Game.currentGame) {
            MissionAnimationView.show(Game.currentGame.getMissionType(), this, {
                amount: score,
                beginPos: BaseCellView.alignInTheGrid(x, y)
            });
        }
    },

    showLantern: function (lanternStreak) {
        var lantern = new cleverapps.Spine(bundles.lantern.jsons.lantern_fly_json);
        lantern.setAnimation(0, "fly" + lanternStreak, false);
        lantern.setLocalZOrder(3);
        this.innerContainer.addChild(lantern);

        cleverapps.audio.playSound(bundles.lantern.urls.lantern_fly_effect);

        var targetPosition = cc.p(this.innerContainer.width / 2, this.innerContainer.height / 2);
        var startPoint = this.convertToNodeSpace(this.getParent().convertToWorldSpace(cc.p(-lantern.width, 0)));
        var endPoint = this.convertToNodeSpace(this.getParent().convertToWorldSpace(cc.p(this.getParent().width + lantern.width, 0)));
        var startMidPoint = cc.p(targetPosition.x, 0);
        var endMidPoint = cc.p(targetPosition.x, 0);

        lantern.setPositionRound(startPoint);

        lantern.runAction(new cc.Sequence(
            new cc.BezierTo(1, [startPoint, startMidPoint, targetPosition]).easing(cc.easeCubicActionOut()),
            new cc.DelayTime(0.4),
            new cc.BezierTo(1, [targetPosition, endMidPoint, endPoint]).easing(cc.easeCubicActionIn()),
            new cc.RemoveSelf()
        ));
    },

    onTouchBeganListener: function (location, cellPosition) {
        if (Game.currentGame.counter.isActive() || Game.currentGame.goalCounter.isActive() || cleverapps.focusManager.isFocused() && !cleverapps.focusManager.isControlEnabled("field")) {
            this.startLocation = undefined;
            return false;
        }

        this.startLocation = {
            x: location.x,
            y: location.y
        };

        this.field.swipeSelected = cellPosition;

        return true;
    },

    onTouchMovedListener: function (location, cellPosition) {
        var cell = this.field.cells[cellPosition.row] && this.field.cells[cellPosition.row][cellPosition.col];
        if (Game.currentGame.counter.isActive() || Game.currentGame.goalCounter.isActive() || cell && !Match3TutorialHelper.checkTutorialCell(cell)) {
            this.startLocation = undefined;
            return;
        }

        this.field.trigger("touchMoved", cell);

        if (this.startLocation) {
            if (Math.abs(this.startLocation.x - location.x) >= Math.abs(this.startLocation.y - location.y)) {
                if (this.startLocation.x - location.x > FieldView.MOVE_TRESHOLD) {
                    this.field.trigger("touchSwiped", {
                        row: 0,
                        col: -1
                    });
                    this.startLocation = undefined;
                } else if (location.x - this.startLocation.x > FieldView.MOVE_TRESHOLD) {
                    this.field.trigger("touchSwiped", {
                        row: 0,
                        col: 1
                    });
                    this.startLocation = undefined;
                }
            } else if (location.y - this.startLocation.y > FieldView.MOVE_TRESHOLD) {
                this.field.trigger("touchSwiped", {
                    row: -1,
                    col: 0
                });
                this.startLocation = undefined;
            } else if (this.startLocation.y - location.y > FieldView.MOVE_TRESHOLD) {
                this.field.trigger("touchSwiped", {
                    row: 1,
                    col: 0
                });
                this.startLocation = undefined;
            }
        }
    },

    onTouchEndedListener: function (location, cellPosition) {
        this.field.swipeSelected = undefined;

        var cell = this.field.cells[cellPosition.row] && this.field.cells[cellPosition.row][cellPosition.col];
        if (Game.currentGame.counter.isActive() || Game.currentGame.goalCounter.isActive() || cell && !Match3TutorialHelper.checkTutorialCell(cell)) {
            this.startLocation = undefined;
            return;
        }

        if (this.startLocation) {
            if (cleverapps.config.debugMode) {
                if (cell) {
                    cell.onDebugListener();
                } else {
                    console.log("Empty: " + cellPosition);
                }
            }
            this.field.trigger("touchEnded", cell);
        }
        this.startLocation = undefined;
    },

    changeSelected: function (selected) {
        if (selected) {
            this.showSelection(selected.y, selected.x);
        } else {
            this.hideSelection();
        }
    }
});

FieldView.MAX_SCALE = 2;
FieldView.MOVE_TRESHOLD = 12;

cleverapps.styles.FieldView = {
    animateWrongMove: bundles.game.jsons.wrong_move_json,
    animateValidMove: bundles.game.jsons.cells_swap_json,
    baseScale: 0.25,

    start: {
        width: 520,

        animation: {
            position: { dy: 195, dx: 0 }
        }
    },

    animation: {
        offsetY: -20
    },

    slideAnimation: {
        field: {
            delay: 0.5
        }
    },

    shake: {
        dx: 2,
        dy: 2
    },

    padding: {
        top: 30,
        right: 30,
        left: 30,
        bottom: 30
    },

    danger: {
        padding: [{
            top: 15,
            right: 15,
            left: 15,
            bottom: 15
        },
        {
            top: 15,
            right: 15,
            left: 15,
            bottom: 15
        },
        {
            top: 20,
            right: 20,
            left: 20,
            bottom: 20
        }]
    },

    filedBg: {
        x: { align: "center" },
        y: { align: "bottom" }
    }
};
