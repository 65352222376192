/**
 * Created by olga on 31.01.2025
 */

var HeroesTutorial = function () {
    return new TutorialScenario("heroes", {
        listSteps: HeroesTutorial.listSteps,
        isAvailable: function () {
            return cleverapps.environment.isHeroesScene()
                && (HeroesTutorial.getUnlockHero() || HeroesTutorial.getUpgradeHero());
        }
    });
};

HeroesTutorial.listSteps = function () {
    var steps = [];
    var toUnlock = HeroesTutorial.getUnlockHero();

    if (toUnlock) {
        steps.push(HeroesTutorial.createStep(toUnlock, Forces.UNLOCK_HEROES));
    }

    var toUpgrade = HeroesTutorial.getUpgradeHero();
    if (toUpgrade) {
        steps.push(HeroesTutorial.createStep(toUpgrade, Forces.UPGRADE_HEROES));
    }

    return steps;
};

HeroesTutorial.createStep = function (color, force) {
    var scene = cleverapps.scenes.getRunningScene();
    
    var options = {
        name: "upgrade_hero",
        onStart: function () {
            this.force = cleverapps.forces.create(
                scene.heroBuildings[color].button, 
                force,
                { actives: [scene.heroBuildings[color]] }
            );
        },

        onExecution: function () {
            cleverapps.tutorial.completeStep();
        },

        onFinish: function () {
            if (this.force && cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(options);
};

HeroesTutorial.getUnlockHero = function () {
    var color = HeroesTutorial.UNLOCK_HERO;
    if (match3.heroes.getLevel(color) === 0 && match3.heroes.isHeroAvailable(color)) {
        return color;
    }
};

HeroesTutorial.getUpgradeHero = function () {
    var color = HeroesTutorial.UPGRADE_HERO;
    if (match3.heroes.getLevel(color) === 1 && match3.heroes.isHeroAvailable(color)) {
        return color;
    }
};

HeroesTutorial.UPGRADE_HERO = "e";
HeroesTutorial.UNLOCK_HERO = "w";
