/**
 * Created by slava on 24.03.17.
 */

var HeroWindow = CleverappsWindow.extend({
    onWindowLoaded: function (options) {
        this.heroColor = options.color;
        this.heroLevel = match3.heroes.getLevel(this.heroColor);

        this.type = options.type;

        if (options.type) {
            this.type = options.type;
        } else if (match3.heroes.isMaxLevel(this.heroColor)) {
            this.type = "info";
        } else if (this.heroLevel > 0) {
            this.type = "upgrade";
        } else {
            this.type = "unlock";
        }

        this.expPrice = match3.heroes.expUpgradePrice(this.heroColor);
        this.coinPrice = match3.heroes.coinUpgradePrice(this.heroColor);

        var styles = cleverapps.styles.HeroWindow;
        var content = this.generateContent();

        var title = "HeroWindow.title." + this.type;

        var button = {
            width: styles.button.width,
            height: styles.button.height,
            text: "OK",
            onPressed: this.close.bind(this)
        };

        var canBuyExp = cleverapps.exp.canTakeExp(this.expPrice) || cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_REGULAR;
        if (canBuyExp) {
            if (this.type === "unlock") {
                button.text = Messages.get("Heroes.Unlock.Button");
                button.onPressed = this.upgrade.bind(this);
            } else if (this.type === "upgrade") {
                button.text = Messages.get("Heroes.Upgrade.Button");
                button.onPressed = this.upgrade.bind(this);
            }
        }

        this._super({
            title: title,
            name: "herowindow",
            content: content,
            button: button,
            openSound: (this.type === "upgrade" || this.type === "unlock") ? bundles.hero_window.urls.upgrade_effect : undefined,
            help: cleverapps.config.name === "riddles" && function () {
                new GuideWindow({
                    name: "HeroGuideWindow",
                    bundle: bundles.hero_guide_window
                });
            }
        });

        var oldScale = this.scale;
        this.scale = 1;
        this.heroView.replaceParentSamePlace(this.window);
        this.scale = oldScale;

        if (cleverapps.forces.isRunningForce()) {
            this._setGlobalZOrder(true);
        }
    },

    upgrade: function () {
        if (cleverapps.exp.canTakeExp(this.expPrice)) {
            if (levels.user.spendHard(cleverapps.EVENTS.SPENT.HERO_UPGRADE, this.coinPrice)) {
                if (cleverapps.exp.takeExp(this.expPrice)) {
                    match3.heroes.incLevel(this.heroColor);

                    this.close();

                    var tutorialStep = cleverapps.tutorial.getCurrentActiveStep();
                    if (tutorialStep && tutorialStep.name === "upgrade_hero") {
                        tutorialStep.execute();
                    }
                }
            }
        } else {
            new ExpInfoWindow();
        }
    },

    onShow: function () {
        this._super();

        if (this.expPriceText) {
            this.expPriceText.setFont(cleverapps.styles.FONTS.HERO_UPGRADE || cleverapps.styles.FONTS.WINDOW_TEXT);
        }
        if (this.coinsPriceText) {
            this.coinsPriceText.setFont(cleverapps.styles.FONTS.HERO_UPGRADE || cleverapps.styles.FONTS.WINDOW_TEXT);
        }
    },

    getStyles: function () {
        return cleverapps.styles.HeroWindow;
    },

    createPriceBlock: function () {
        var styles = this.getStyles();

        var price = new cc.Node();
        price.setAnchorPoint(0.5, 0.5);

        var expPriceText = new TextWithIcon("%%" + this.expPrice, {
            font: cleverapps.styles.FONTS.HERO_UPGRADE || cleverapps.styles.FONTS.WINDOW_TEXT,
            icons: {
                "%%": bundles.reward_icons.frames.reward_exp_png
            },
            iconScale: styles.canUpgrade.price.exp.scale
        });

        this.expPriceText = expPriceText;
        price.addChild(expPriceText);

        var coinsPriceText = new TextWithIcon("%%" + this.coinPrice, {
            font: cleverapps.styles.FONTS.HERO_UPGRADE || cleverapps.styles.FONTS.WINDOW_TEXT,
            icons: {
                "%%": bundles.reward_icons.frames.reward_gold_png
            },
            iconScale: styles.canUpgrade.price.coin.scale
        });
        this.coinsPriceText = coinsPriceText;
        price.addChild(coinsPriceText);

        cleverapps.UI.arrangeWithMargins(price.children, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.canUpgrade.margin
        });
        cleverapps.UI.wrap(price);

        return price;
    },

    createHelpText: function (message) {
        var styles = this.getStyles();

        var text = cleverapps.UI.generateTTFText(message, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setDimensions(styles.helpBlock.text.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);

        return text;
    },

    createHelpBlock: function () {
        var styles = this.getStyles();

        var animationNode = new cc.Node();
        animationNode.setAnchorPoint(0.5, 0.5);
        animationNode.setContentSize2(styles.helpBlock.animation);

        var animation = new cleverapps.Spine(bundles.hero_window.jsons.hero_window_help_json);
        animation.setSkin(ColorComponent.CODE_COLORS[this.heroColor]);
        animationNode.addChild(animation);
        animation.setPositionRound(styles.helpBlock.animation);
        animation.setAnimation(0, "animation", true);

        var textsLayout = new cleverapps.Layout([this.createHelpText("HeroWindow.help"), this.createHelpText("HeroWindow.help2")], {
            margin: styles.helpBlock.text.margin,
            direction: cleverapps.UI.VERTICAL
        });

        return new cleverapps.Layout([animationNode, textsLayout], {
            margin: styles.helpBlock.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    createStatsBlock: function () {
        var styles = this.getStyles();

        var stats = match3.heroes.listStats(this.heroColor, this.type === "upgrade" || this.type === "unlock");
        var prevStats = stats;
        if (this.type === "upgrade") {
            prevStats = match3.heroes.listStats(this.heroColor);
        }

        var lines = [], layout;

        for (var stat in stats) {
            var value = stats[stat];
            var cur = "";
            if (stat === "makeHeroProb") {
                cur = "%";
            }
            var deltaValue = value - prevStats[stat];

            var items = [];

            var line = new cc.Node();
            line.setAnchorPoint(0.5, 0.5);

            var key = cleverapps.UI.generateOnlyText("HeroWindow.stats." + stat, cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            items.push(key);

            var valueText = prevStats[stat];
            value = cleverapps.UI.generateTTFText(valueText + cur, cleverapps.styles.FONTS.HERO_STATS_TEXT || cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            value.setScale(value.scale * 1.25);
            value.y = styles.statsBlock.digits.dy;
            items.push(value);

            if (deltaValue) {
                var add = cleverapps.UI.generateTTFText("+ " + deltaValue + cur, cleverapps.styles.FONTS.HERO_STATS_UPGRADE_VALUE);
                add.y = styles.statsBlock.digits.dy;
                add.setScale(add.scale * 1.25);
                items.push(add);
            }

            layout = new cleverapps.Layout(items, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.statsBlock.margin
            });

            lines.push(layout);
        }

        var activateText = this.createActivateText();
        if (activateText && cleverapps.config.name !== "riddles") {
            lines.push(activateText);
        }

        var statsLayout = new cleverapps.Layout(lines, {
            margin: styles.statsBlock.rowMargin,
            direction: cleverapps.UI.VERTICAL
        });

        lines.forEach(function (line) {
            line.setPositionRound({ align: "left" }, line.y);
        });
        this.heroView = this.createHero();

        layout = new cleverapps.Layout([this.heroView, statsLayout], {
            margin: styles.statsBlock.heroMargin,
            direction: cleverapps.UI.HORIZONTAL
        });

        return layout;
    },

    createActivateText: function () {
        var styles = this.getStyles().activateText;

        var activateType = match3.heroes.getHero(this.heroColor, {
            nextLevel: (["upgrade", "unlock"].indexOf(this.type) !== -1)
        });
        var prevActivateType = activateType;
        if (this.type === "upgrade") {
            prevActivateType = match3.heroes.getHero(this.heroColor);
        }
        if (activateType.activateByClick !== cleverapps.HERO_ACTIVATE_BY_CLICK_NONE) {
            var s = "Heroes.CanActivateByDblClick";
            if (activateType.activateByClick === cleverapps.HERO_ACTIVATE_BY_CLICK_NO_MOVE) {
                s = "Heroes.CanActivateByDblClickNoMove";
            }
            var activateText = cleverapps.UI.generateTTFText("" + s, activateType.activateByClick !== prevActivateType.activateByClick ? cleverapps.styles.FONTS.HERO_STATS_UPGRADE_VALUE : cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
            activateText.setDimensions(styles.width, 0);
            activateText.fitTo(undefined, styles.height);
            activateText.setHorizontalAlignment(styles.horizontalAlignment);
            return activateText;
        }
    },

    createHero: function () {
        var styles = this.getStyles().hero;

        var hero = new cc.Node();
        hero.setLocalZOrder(3);
        hero.setAnchorPoint(0.5, 0.5);
        hero.setContentSize2(styles);

        var color = this.heroColor.toUpperCase();
        var json = cleverapps.skins.getSlot(color + "_hero_spine_json") || bundles.heroes.jsons[color + "_hero_spine_json"];
        var animation = this.animation = new cleverapps.Spine(json);
        animation.setScale(styles.scale);
        animation.setSkin(match3.heroes.getCurrentSkin());
        animation.setPositionRound(styles);
        hero.addChild(animation);

        AnimationsLibrary.rays(hero, {
            skin: cleverapps.config.name === "riddles" ? "blue" : undefined,
            size: styles.raysSize
        });

        return hero;
    },

    generateContent: function () {
        var styles = cleverapps.styles.HeroWindow;

        var items = [];

        var color = this.heroColor.toUpperCase();

        if (this.type === "upgrade" || this.type === "unlock") {
            items.unshift(this.createPriceBlock());
        }

        if (cleverapps.config.name !== "riddles") {
            items.unshift(this.createHelpBlock());
        } else {
            var activateText = this.createActivateText();
            if (activateText) {
                items.unshift(activateText);
            }
        }

        var text = cleverapps.UI.generateOnlyText("HeroWindow." + color, cleverapps.styles.FONTS.HERO_ABILITIES_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT);
        text.setDimensions(styles.descriptionText.width, 0);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.descriptionText.height);
        items.unshift(text);

        items.unshift(this.createStatsBlock());

        var layout = new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        var actions = ["idle", "idle", "out"];
        this.runSequence(this.animation, actions);

        return layout;
    },

    runSequence: function (animation, actions) {
        var index = 0;

        var next = function () {
            var action = actions[index];

            index++;
            if (actions.length === index) {
                index = 0;
            }

            animation.setAnimation(0, action, false);
            animation.setCompleteListener(function () {
                next();
            });
        };

        next();
    },

    listBundles: function () {
        return ["hero_window"];
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HERO_STATS_UPGRADE_VALUE: {
        size: 30,
        color: cleverapps.styles.COLORS.HERO_STATS_UPGRADE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.HeroWindow = {
    margin: 20,

    canUpgrade: {
        margin: 70,
        price: {
            margin: 10,
            exp: {
                scale: 0.9
            },
            coin: {
                scale: 0.9
            }
        }
    },

    descriptionText: {
        width: 600,
        height: 120
    },

    activateText: {
        horizontalAlignment: cc.TEXT_ALIGNMENT_LEFT,
        width: 350,
        height: 100
    },

    helpBlock: {
        margin: 30,
        text: {
            margin: 50,
            width: 300
        },
        animation: {
            width: 300,
            height: 300,
            x: 150,
            y: 150
        }
    },

    statsBlock: {
        margin: 5,
        rowMargin: 0,
        heroMargin: 40,
        digits: {
            dy: 2
        }
    },

    hero: {
        width: 210,
        height: 210,

        raysSize: 170,

        scale: 1.5,
        x: { align: "center" },
        y: { align: "center", dy: -10 }
    },

    button: {
        width: 300,
        height: 100
    },

    animation: {
        y: 30
    }
};
