/**
 * Created by slava on 14/8/17
 */

var AvailableMoveView = cc.Node.extend({
    ctor: function (availableMove) {
        this._super();

        this.availableMove = availableMove;

        availableMove.on("changeVisible", this.setVisible.bind(this), this);
    },

    setVisible: function (visible) {
        this.removeFinger();
        if (this.cells) {
            this.cleanCells();
        }

        if (visible) {
            if (this.availableMove.getMoveType() === Match3.SMART_HINT) {
                this.showBoosterAdvice();
            } else if (this.availableMove.getMoveType() === Match3.DEFAULT_HINT) {
                this.showSwapAdvice();
            }
        }
    },

    showBoosterAdvice: function () {
        var moveOptions = this.availableMove.getMoveOptions();
        var clicks = [
            cleverapps.boosters.getBoosterById(moveOptions.boosterId).onGetView(),
            moveOptions.target
        ].filter(Boolean);

        this.finger = FingerView.hintClick(clicks, { repeatDelay: 3 });
    },

    showSwapAdvice: function () {
        var moveOptions = this.availableMove.getMoveOptions();

        var cellA, cellB;
        var cells = [];
        if (moveOptions.shape) {
            cells = moveOptions.shape.cells;
        } else {
            cellA = moveOptions.start;
            cellB = Game.currentGame.field.cells[cellA.y + moveOptions.dir.row] && Game.currentGame.field.cells[cellA.y + moveOptions.dir.row][cellA.x + moveOptions.dir.col];
            if (!cellA || !cellB) {
                return;
            }
            cells.push(cellA);
            cells.push(cellB);
        }

        cells.forEach(function (cell) {
            var currentCell = cell.innerCell || cell;
            currentCell.onAvailableMoveListener(moveOptions);
        });
        cleverapps.audio.playSound(bundles.game.urls.available_move_effect);

        this.cells = cells;
    },

    removeFinger: function () {
        FingerView.remove(this.finger);
        this.finger = undefined;
    },

    cleanCells: function () {
        this.cells.forEach(function (cell) {
            var currentCell = cell.innerCell || cell;
            currentCell.onStartMoveListener({ x: cell.x, y: cell.y }, { moveInterval: 0.1, easing: cc.easeOut(1) });
        });

        this.cells = undefined;
    }
});
